export default {
  title:
    "Découvrez enfin votre potentiel <blockquote class='bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-yellow-400'>solaire</blockquote>",
  tc: "L'inscription vaut acceptation des <a class='underline' href='/help/guides/installers/legal/mentions-legales#0'>conditions générales</a>, et notre <a class='underline' href='/help/guides/installers/legal/declaration-de-confidentialite'>déclaration de confidentialité</a>.",
  "already-registered": "Déjà chez projetsolaire ? ",
  "type-select": "Vous êtes...",
  "company-name": "Votre raison sociale",
  "company-legal-name": {
    placeholder: "Entrez le nom légal de votre enprise",
    label: "Nom légal de l'entreprise",
  },
  siret: {
    label: "Entrez votre siret",
    placeholder: "Siret",
  },
  "company-representative-firstname": {
    placeholder: "Entrez le prénom du responsable de votre entreprise",
    label: "Prénom du représentant de la entreprise",
  },
  "company-representative-lastname": {
    placeholder: "Entrez le nom du responsable de votre entreprise",
    label: "Nom du représentant de l'entreprise",
  },
  "company-representative-email": {
    placeholder: "Entrez l'email du responsable de votre entreprise",
    label: "Email du représentant de l'entreprise",
  },
  "enter-your-company-name": "Entrez votre raison sociale",
  "password-confirm": "Confirmez votre mot de passe",
  email: {
    placeholder: "Entrez votre email",
    label: "Email",
  },
  password: {
    placeholder: "Entrez votre mot de passe",
    label: "Mot de passe",
    required: "Le mot de passe est requis",
    "min-length": "Le mot de passe doit contenir au moins 8 caractères",
    "upper-case": "Le mot de passe doit contenir au moins une lettre majuscule",
    "lower-case": "Le mot de passe doit contenir au moins une lettre minuscule",
    symbol:
      "Le mot de passe doit contenir au moins un caractère spécial (@$!%*?&)",
    number: "Le mot de passe doit contenir au moins un chiffre",
  },
  "confirm-password": {
    placeholder: "Confirmez votre mot de passe",
    label: "Confirmation mot de passe",
    "should-match": "Mots de passe doivent correspondre",
  },
  "phone-number": {
    label: "Numéro de téléphone",
    placeholder: "0-00-00-00-00",
  },
  "title-of-user": {
    label: "Civilité",
    type: {
      mr: "Monsieur",
      mrs: "Madame",
      miss: "Mademoiselle",
    },
  },
  firstname: {
    label: "Entrez votre prénom",
    placeholder: "Votre prénom",
  },
  lastname: {
    label: "Entrez votre nom",
    placeholder: "Votre nom",
  },
  dob: {
    label: "Entrez votre date de naissance",
    placeholder: "Votre date de naissance",
  },
  pob: {
    label: "Entrez votre lieu de naissance",
    placeholder: "Votre lieu de naissance",
  },
  type: {
    label: "Type d'utilisateur",
    description: "Quel type d'utiilsateur êtes-vous ?",
    residential: "Résidentiel",
    business: "Business",
    householder: "Propriétaire",
    installer: "Professionnel",
  },
  "required-field": {
    type: "Veuillez sélectionnez un type d'utilisateur",
    email: "Veuillez entrez votre adresse email",
    dob: "Veuillez entrez votre date de naissance",
    pob: "Veuillez entrez votre lieu de naissance",
  },
  success: {
    title:
      "Bienvenue ! Veuillez consulter votre email avant de pouvoir accéder à projetsolaire.",
    description:
      'Veuillez consulter votre boîte mail afin de confirmer la création de votre compte. Si vous ne voyez pas l\'email de confirmation tout de suite, veuillez vérifier votre dossier "spam" ou "courrier indésirable" et marquez-le "non-spam". Le message de confirmation s\'y retrouve parfois par erreur. Si vous avez une question n\'hésitez pas à nous contacter par courrier électronique.',
  },
  callback: {
    success: {
      title: "Inscription réussie",
      description: "L'email de votre compte a été validé avec succès",
    },
    "resend-email-success": {
      title: "Email d'activation envoyé",
      description:
        "Un nouvel email d'activation a été envoyé. Consulter votre boîte de réception pour activer votre compte.",
    },
    errors: {
      "token-invalid": "Votre lien d'activation est invalide",
      "token-missing": "Votre lien d'activation est invalide",
      "token-expired": "Votre lien d'activation a expiré",
    },
    invites: {
      installer: {
        title:
          "Vous êtes invité par {installerCompanyName} pour rejoindre votre équipe solaire",
        description:
          "Veuillez confirmer l’invitation pour créer votre compte à vous et obtenir l’accès à l’espace professionnel de {installerCompanyName} en ligne.",
        button: "Initialiser votre mot de passe",
      },
      user: {
        title:
          "{installerCompanyName} vous a invité à suivre l'avancement en ligne de votre projet solaire.",
        description:
          "Veuillez renseigner un mot de passe pour créer votre compte et obtenir l'accès à votre espace personnel.",
        button: "Accéder à mon espace personnel",
      },
      lead: {
        title: "Vous avez été invité à rejoindre la plate-forme projetsolaire",
        description:
          "Merci de bien vouloir crée votre compte en cliquant sur le boutton ci-dessous afin d’accéder à votre espace personnel.",
        button: "Créer mon compte",
      },
    },
    buttons: {
      "resend-callback-link": "Renvoyer un lien d'activation",
      "send-callback-link": "Envoyer un lien d'activation",
    },
  },
  errors: {
    duplicateEmailEnabled: "Votre email est associé à un compte existant.",
    duplicateEmailNotEnabled:
      "Votre email est associé à un compte existant mais non activé. Veuillez vérifier votre boite de réception.",
    "account-not-enabled":
      "Un installateur a déjà commencé un projet en votre nom. Vous pouvez accéder à votre projet en demandant un lien d'activation. Il ne vous restera plus qu'à activer votre compte et définir votre mot de passe avec le lien reçu par email.",
    unknown: "Une erreur est survenue.",
  },
  enable: {
    callback: {
      title: "Définir votre mot de passe",
      "description-1":
        "« Pour protéger vos informations, il est nécessaire de choisir et d’utiliser des mots de passe robustes, c’est-à-dire difficiles à retrouver à l’aide d’outils automatisés et à deviner par une tierce personne »",
      "description-2": "www.interieur.gouv.fr",
      "confirm-password": "Valider votre mot de passe",
    },
  },
}
