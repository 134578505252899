export default {
  leads: {
    heading: {
      title: "Bonjour {fullName}!",
      subtitle:
        "Pour nous aider à modéliser le potentiel solaire de votre bien, nous avons besoin de quelques informations. Cela ne vous prendra que quelques minutes !",
    },
    "verifying-token": "Vérification de votre accès",
    submitted: "Vous avez partagez vos informations avec succès !",
    steps: {
      "customer-information": {
        title: "Vos informations personnelles",
        subtitle: "Confirmez vos informations personnelles",
      },
      address: {
        title: "Votre adresse",
        subtitle: "Confirmez votre adresse",
      },
      "electricity-bill": {
        title: "Votre facture d électricité",
        subtitle: "Téléchargez votre facture d'électricité",
        label: "Facture d'électricité",
        "paragraph-1":
          "Pour pouvoir modéliser votre maison avec précision, nous avons besoin de votre facture d'électricité. Cela nous aide à projeter vos rendements avec votre contrat d'électricité réel.",
      },

      confirm: {
        title: "Valider informations",
        subtitle:
          "Une fois que vous êtes satisfait, soumettez vos informations pour votre modèle personnalisé",
        "paragraph-1":
          "Si vous êtes satisfait de vos informations, cliquez sur 'Envoyer' et nous commencerons à préparer votre modèle personnalisé dans les 48 heures.</br>",
      },
    },

    errors: {
      "token-invalid": "L'accès est invalide",
      "token-missing": "L'accès est invalide",
    },
  },
  "enedis-consumption-permission": {
    intro: {
      title:
        "Analyser les économies sur la facture à partir de l'historique horaire de consommation du compteur Linky",
      description:
        "En tant qu'entité référencée auprès d'Enedis, nous pouvons analyser votre courbe de charge pour calculer votre autoconsommation électrique heure par heure. C'est essentiel pour optimiser le dimensionnement de votre installation photovoltaïque, et obligatoire pour comparer les solutions de stockage",
      footer:
        "Veuillez-nous autoriser à obtenir de façon sécurisée les mesures horaires de votre historique de consommation",
      mandated: {
        text: "Le consentement est autorisé au Mandataire la société élance Technologies Propres S.A.S, domiciliée 31 Avenue de Ségur, 75007 Paris, immatriculée au registre de commerce de Paris sous le numéro 878 632 306 opérant sous la marque déposée de « <strong>projetsolaire</strong> ». Pour plus d'informations <a href='https://projetsolaire.com/help/guides/installers/presentation/mandat-special-de-representation-aupres-denedis' target='_blank' class='text-indigo-600' >cliquez ici.</a>"
      },
      button: "J’autorise le partage des mesures",
    },
    success: {
      title: "Votre mandat a été signé avec succès",
      description:
        "Vous avez un compte ? Connectez-vous pour suivre la progression de votre projet solaire",
      "sign-in": "Connexion",
      "create-an-account": "ou crée un compte",
    },
  },
  administration: {
    heading: {
      title: "Bonjour {fullName}!",
      subtitle:
        "Pour compléter vos services, nous avons besoin de quelques informations. Cela ne vous prendra que quelques minutes à remplir !",
      "subtitle-for-installer":
        "Pour compléter les services de {customerName} au {fullAddress}, nous avons besoin des informations suivantes. Cela ne vous prendra que qu'une seule minute à remplir !",
    },

    submitted: "Vous avez partagez vos informations avec succès !",
    "verifying-token": "Vérification de votre accès",
    steps: {
      "customer-information": {
        title: "Vos informations personnelles",
        subtitle: "Confirmez vos informations personnelles",
        "first-name": "Prenom",
        "last-name": "Nom",
        phone: "Téléphone",
        email: "Email",
        type: "Type",
        "company-account": {
          "company-name": "Nom de l'entreprise",
          "company-legal-name": "Nom légal de l'entreprise",
          siret: "SIRET de l'entreprise",
          "primary-email": "E-mail principal",
          "primary-phone": "Téléphone principal",
          "company-representative-first-name":
            "Prénom du représentant de l'entreprise",
          "company-representative-last-name":
            "Nom du représentant de l'entreprise",
          "company-representative-email":
            "E-mail du représentant de l'entreprise",
        },
        "residential-account": {
          title: "Titre",
          "select-title": "Sélectionner un titre",
          "first-name": "Prénom",
          "last-name": "Nom",
          email: "E-mail",
          "date-of-birth": "Date de naissance",
          phone: "Téléphone",
          "place-of-birth": "Lieu de naissance",
        },
      },
      address: {
        title: "Votre adresse",
        subtitle: "Confirmez votre adresse",
      },
      images: {
        title: "Images",
        subtitle: "Ajouter les images",
        "technical-images": "Photos techniques",
        "building-images": "Photos du bâtiment",
      },
      documents: {
        title: "Documents",
        subtitle:
          "Télécharger le document demandé pour compéter votre services",
        "paragraph-1":
          "Pour pouvoir modéliser votre maison avec précision, nous avons besoin de votre facture d'électricité. Cela nous aide à projeter vos rendements avec votre contrat d'électricité réel.",
        kbis: "KBIS",
        "property-deed": "titre de propriété",
        "property-taxes": "Taxes foncières",
        "string-diagram": "Plan de chaînage",
        "mayor-approval": "avis de non-opposition",
        "electricity-bill": "facture électrique",
        "consuel-approval": "AC du Consuel",
        "manual-mayor-single-mandate":
          "Signature du mandat spécial de représentation - Autorisation d’urbanisme",
        "manual-enedis-single-mandate":
          "Signature du mandat spécial de représentation - Demande de raccordement",
        "wiring-diagram": "Schéma électrique unifilaire",
        "roof-leasing-contract": "Attestation de mise à disposition",

        esign: {
          "mayor-single-mandate":
            "Signature du mandat spécial de représentation - Autorisation d’urbanisme",
          "enedis-single-mandate":
            "Signature du mandat spécial de représentation - Demande de raccordement",
          "dual-mandate": "Signature du mandat spécial de représentation",
        },
      },
      hardware: {
        title: "Matériel",
        subtitle: "Ajouter l'inventaire avec lequel le système est installé",
        manufacturer: "Fabricant",
        model: "Modèle",
        quantity: "Quantité",
        panelLayoutDescription: "Description du calepinage",
        panel: {
          title: "Panneaux",
          add: "Ajouter panneau",
        },
        inverter: {
          title: "Onduleurs",
          add: "Ajouter onduleur",
        },
        battery: {
          title: "Batteries",
          add: "Ajouter batterie",
        },
        "delete-dialog": {
          title: "Supprimer l'élément",
          description:
            "Êtes-vous sûr de vouloir supprimer cet élément de votre matériel ?",
        },
      },
      technicals: {
        title: "Détails technique",
        subtitle: "Ajouter les informations techniques de l'installation",
        "paragraph-1":
          "Si vous êtes satisfait de vos informations, cliquez sur 'Envoyer' et nous commencerons à préparer votre modèle personnalisé dans les 48 heures.</br>",
        "system-size": "Puissance du système (kWc)",
        "usage-point": "numéro du Point De Livraison (PDL)",
        "meter-container-sizes": "largeur du coffret",

        "grid-connection-type": {
          title: "Le type de branchement est en",
          types: {
            MONOPHASE: "Monophasé",
            TRIPHASE: "Triphasé",
            BT: "Basse tension > 36 kVA (BT>36)",
            HTA: "Haute tension (HTA)",
          },
        },

        "consumption-regime": {
          title: "Régimes de raccordement",
          types: {
            FULL_SALE: {
              title: "Vente en totalité",
              description:
                "Étudiez l'injection en totalité sur cette propositon.",
            },
            PARTIAL_SELF_CONSUMPTION: {
              title: "Autoconsommation (Injection du surplus)",
              description:
                "Étudiez l'autoconsommation avec injection du surplus sur cette propositon.",
            },
            FULL_SELF_CONSUMPTION: {
              title: "Autoconsommation (Totale)",
              description:
                "Autoconsommation sans vente de surplus sur cette propositon.",
            },
          },
        },

        "panel-technology": {
          types: {
            MONOCRYSTALLINS: "Monocristallines",
            POLYCRYSTALLINS: "Polycristallines",
          },
          title: "Technologie des cellules",
        },
        "existing-installation": {
          "installation-parts-modified": {
            title: "Pièces d'installation modifiées",
            types: {
              AC: "AC",
              DC: "DC",
              AC_AND_DC: "AC et DC",
            },
          },
        },
      },
      confirm: {
        title: "Valider informations",
        "paragraph-1":
          "Confirmez que vous êtes d'accord pour soumettre toutes les informations saisies. Nous commencerons alors à compléter vos services pour vous.</br>",
      },
    },

    errors: {
      "token-invalid": "L'accès est invalide",
      "token-missing": "L'accès est invalide",
    },
  },
  errors: {
    "token-invalid": "L'accès est invalide",
    "token-missing": "L'accès est invalide",
  },
}
